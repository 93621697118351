import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Head from 'next/head';
import getConfig from 'next/config';

import { loadFront } from 'redux/modules/front';
import {
  disableLazyLoadAction,
  setPageView,
  setSection,
} from 'redux/modules/shared';
import { loadScheduleAction } from 'redux/modules/tve';

import { layout as servicesLayoutPropType } from 'lib/CustomPropTypes/services';
import { schedule as schedulePropType } from 'lib/CustomPropTypes/TVE';
import { navbar, NAVBAR_THEME } from 'lib/navbar';
import { TVE_CANONICAL_ROUTE } from 'lib/tve';
import { setLinkHeaders } from 'lib/setLinkHeaders';
import { FeatureFlagContext } from 'lib/ContextTypes';

import AdsBundle from 'components/AdsBundle';
import { ThemesCssBundle } from 'components/ThemesCssBundle';
import { AnalyticsLaunchScripts } from 'components/AnalyticsLaunchScripts';
import { HeaderAndFooter } from 'components/services/HeaderAndFooter';
import WithTVEAuthProvider from 'components/TVE/AuthProvider';
import CallToAction from 'components/TVE/CallToAction';
import Curated from 'components/TVE/Curated';
import Info from 'components/TVE/Info';
import Meta from 'components/Meta';
import { GlobalMetadata } from 'components/PageMetadata';
import Player from 'components/TVE/Player';
import ProviderSelect from 'components/TVE/ProviderSelect';
import Schedule from 'components/TVE/Schedule';
import TempPass from 'components/TVE/TempPass';
import { ERROR_TYPES } from 'components/TVE/Slate';
import IconfontStyleTag from 'components/IconfontStyleTag';

import 'assets/styles/main.scss';
import 'assets/styles/toolkit.scss';
import globalContainerStyles from '../globalContainerStyles.module.scss';
import './styles.themed.scss';


const {
  publicRuntimeConfig: {
    DISABLE_AUTH_TVE_MSNBC,
  },
} = getConfig();

const block = 'tve-view';
const pageView = 'front';

const navbarConfig = {
  theme: () => NAVBAR_THEME.DARK,
};

const mapStateToProps = ({ tve, video }) => ({
  authenticated: tve.authenticated,
  epgCurrent: tve.epgCurrent,
  hasTempPass: tve.hasTempPass,
  providerSelectVisible: tve.providerSelectVisible,
  videoError: video.error,
});


class TVEPage extends React.Component {
  static contextType = FeatureFlagContext;

  // Load front data
  static getInitialProps = async (ctx) => {
    const {
      req: {
        query: {
          lazyload,
        },
      },
      res: {
        locals: {
          vertical,
        },
      },
      store,
    } = ctx;
    const promises = [
      store.dispatch(setPageView(pageView)),
      store.dispatch(setSection(TVE_CANONICAL_ROUTE)),
      store.dispatch(loadFront(vertical, TVE_CANONICAL_ROUTE)),
      store.dispatch(loadScheduleAction(vertical)),
    ];

    if (lazyload === 'false') {
      promises.push(store.dispatch(disableLazyLoadAction()));
    }

    await Promise.all(promises);

    setLinkHeaders(ctx.res, vertical);

    return {
      navigating: false,
      pageView,
    };
  };

  static propTypes = {
    authenticated: PropTypes.bool,
    epgCurrent: PropTypes.shape(schedulePropType),
    hasTempPass: PropTypes.bool,
    layout: servicesLayoutPropType.isRequired,
    providerSelectVisible: PropTypes.bool,
    vertical: PropTypes.string.isRequired,
    videoError: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
  };

  static defaultProps = {
    authenticated: null,
    epgCurrent: {},
    hasTempPass: null,
    providerSelectVisible: false,
    videoError: false,
  };


  constructor(props) {
    super(props);
    this.iframeRef = React.createRef();
  }

  render() {
    const {
      authenticated,
      epgCurrent,
      hasTempPass,
      layout,
      providerSelectVisible,
      vertical,
      videoError,
    } = this.props;

    const title = epgCurrent?.program?.title ?? null;
    const description = epgCurrent?.program?.description ?? null;

    const { 'curator-live-video-msnbc': curatorFF } = this.context;

    return (
      <>
        <Head>
          <IconfontStyleTag />
        </Head>
        <ThemesCssBundle vertical={vertical} />

        <div id="content" className={globalContainerStyles.container}>
          <HeaderAndFooter layout={layout}>
            <GlobalMetadata />
            <Meta
              title="MSNBC Live: Stream the latest TV shows"
              description="Live stream MSNBC, join the MSNBC community and watch full episodes of your favorite MSNBC shows, including Rachel Maddow, Morning Joe and more."
            />

            {!curatorFF && (
              <>
                {!DISABLE_AUTH_TVE_MSNBC && (
                  <>
                    <WithTVEAuthProvider iframeRef={this.iframeRef} />
                    {videoError !== ERROR_TYPES.BROWSER && (
                      <TempPass
                        checkTempPass={authenticated === false && hasTempPass === false}
                      />
                    )}
                  </>
                )}

                <section className={block}>
                  <div className={`${block}__inner`}>
                    {!DISABLE_AUTH_TVE_MSNBC && (
                      <>
                        {providerSelectVisible && (
                          <ProviderSelect iframeRef={this.iframeRef} />
                        )}
                        <CallToAction />
                      </>
                    )}

                    <div className={`${block}__player-outer`}>
                      <Player bypassAuth={DISABLE_AUTH_TVE_MSNBC} autoplayMuted />
                    </div>

                    <div className={`${block}__info-outer`}>
                      <div className={`${block}__info-inner`}>
                        <Info
                          description={description}
                          title={title}
                        />
                      </div>
                    </div>

                    <div className={`${block}__schedule-outer`}>
                      <Schedule />
                    </div>
                  </div>
                </section>
              </>
            )}

            <div className="layout-grid-container mb8">
              <div className="layout-grid-item">
                <Curated />
              </div>
            </div>
          </HeaderAndFooter>
        </div>

        <AnalyticsLaunchScripts />
        <AdsBundle />
      </>
    );
  }
}

export default navbar(navbarConfig)(
  connect(mapStateToProps)(
    TVEPage,
  ),
);
